<template>
  <div id="app">
      <router-view />
  </div>
</template>
<script>
export default{
  name:'App',
  components:{
   

  }
}
</script>
<style>
	#app{
		width:100%;
		/* text-align: center; */
	}
</style>