<template>
	<div class="headerContainer">
		<div class="containerLeft"><img :src="require('@/assets/img/logo.png')" alt="">
		<!-- <span class="leftRight">Influences</span> -->
		</div>
		<div class="containerMiddle">
			<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
			  <el-menu-item index="1">Home</el-menu-item>
			  <el-menu-item index="2">Journal</el-menu-item>
			  <el-menu-item index="3">Documents</el-menu-item>
			  <el-menu-item index="4">About</el-menu-item>
			</el-menu>
		</div>
		<div class="containerRight"><span class="containerRight_text">What is this page?</span></div>
	</div>
</template>

<script>
	export default {
		name: 'influenceHeader',
		data(){
			return{
				activeIndex: '1',
			}
			 
		},
		methods:{
			//导航栏跳转
			handleSelect(index) {
			  this.$router.push(index);
			}, 
		}
		
	}
</script>

<style lang="scss" scoped>
	.headerContainer {
		width: 100%;
		background-color: #f5f9fd;
		padding: 19px 0px;
		display: flex;
		justify-content: space-around;
	}

	.containerLeft{
		display: flex;
		align-items: center;
		margin-left: 107px;
	}
	.leftRight {
		width: 169px;
		font-family: Raleway;
		font-size: 36px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 26px;
		letter-spacing: 0px;
		color: #fefefe;
	}
	

	.containerLeft>img {
		height: 62px;
	}

	
	.containerRight_text{
		text-align: center;
		width: 8.375rem;
		padding: 16px 17px;
		background-color: #01395a;
		font-family: SourceHanSansCN-Regular;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 14px;
		letter-spacing: 0px;
		color: #c1e5ef;
	}
	//导航栏修改
	.el-menu{
		border:0;
		background-color: unset;
	}
	.el-menu-item{
		font-size: 18px;
		color: #000000;
	}
	.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover{
		background-color: transparent;
		color:#509fcd;
	}
	.el-menu--horizontal .el-menu-item:not(.is-disabled):focus{
		background-color: unset;
	}
	.el-menu--horizontal>.el-menu-item.is-active{
		border-bottom-color: #509fcd;
		color:#509fcd;
	}
</style>