import Vue from 'vue'
import VueRouter from 'vue-router'
import Article from '@/pages/journal/article.vue'
import Index from '@/pages/index.vue'
Vue.use(VueRouter)


export default new VueRouter({
    routes:[
		
		{
			path:"/",
			redirect:"/index"
		},
		
		{
		    path:"/index",
		    component:Index,
		},
		       
		
        {
            path:"/article",
            component:Article,
 
        },
		
    ]
})


