<template>
	<basic-container>
		<div class="box">
			<div class="box_left">
				<div class="box_left_top">
					<div class="mark"><img :src="require('@/assets/img/mark.png')" alt="">
						<span class="markText">8</span>
					</div>
				</div>
				<div style="text-align: center;"><span style="font-weight: bold;color: #000;text-align: center;font-family: PingFang-SC-Regular;">Metrics</span></div>
				<div class="box_left_middle">
					<p
						style="padding: 15px 0px;margin:0px;text-align: center;background-color: hsl(228deg, 45.45%, 97.84%);">
						Citation Distribution <span class="question0" data-title="This is the total number of times a publication has been cited. We count citations from
						the reference list in all publications that have been indexed by Dimensions."><img class="question"
								:src="require('@/assets/img/question.png')" alt=""></span></p>
					<div class="citation_block">
						<div class="citation_block1">
							<div style="height: 6px; background-color: #5e33bf;"></div>
							<div style="border-bottom: 1px solid #f5f1f1;color: #5e33bf;font-weight: bold">W</div>
							<div>1</div>
						</div>
						<div class="citation_block1">
							<div style="height: 6px; background-color: #ff8200;"></div>
							<div style="border-bottom: 1px solid #f5f1f1;color: #ff8200;font-weight: bold">S</div>
							<div>1</div>
						</div>
						<div class="citation_block1">
							<div style="height: 6px; background-color: #1da6b8;"></div>
							<div style="border-bottom: 1px solid #f5f1f1;font-weight: bold"><img
									:src="require('@/assets/img/c.png')" alt=""></div>
							<div>1</div>
						</div>
						<div class="citation_block1">
							<div style="height: 6px; background-color:#e5e5e5;"></div>
							<div style="border-bottom: 1px solid #f5f1f1;font-weight: bold">N</div>
							<div>1</div>
						</div>
					</div>
				</div>
				<div class="box_left_bottom">
					<div class="box_left_bottom_text">Generate Citation Report</div>
				</div>
			</div>
			<div class="box-right">
				<div class="box-right-top">
					<p>Emotional Processing As A Function Of Different Probabilistic Contexts: A High-density Eeg
						investigation</p>
					<div style="display: flex;justify-content: space-between;">
						<div class="text_left">
							<p><span class="first">Publication:</span><span class="second_text"> Article in <span
										style="font-weight: bold;color: #000;">Current Medicinal Chemistry</span>,
									published January 2022</span></p>
							<p><span class="first">Orcid:</span><span class="second_text"> 0000-0002-7661-0521</span>
							</p>
							<p><span class="first">Author:</span><span class="second_text"> Sirajo Murtala, Mohammed
									lbrahim, Sagir Lawal and Buhari Baba Abdullah</span></p>
							<p><span class="first">DOI:</span><span
									class="second_text">https://doi.org/10.1186/s12898-020-00337-z</span></p>
						</div>
						<div class="text-right">
							<div>Citation Alerts Subscription</div>
							<div>
								<p
									style="margin-bottom:5px;font-family: PingFang-SC-Bold;font-size: 20px;font-weight: normal;font-stretch: normal;line-height: 26px;letter-spacing: 0px;color: #010101;">
									Share this page</p>
								<div style='display: flex;justify-content: space-between;width: 149px;'>
									<img :src="require('@/assets/img/index_03.png')" alt=""><img
										:src="require('@/assets/img/index_05.png')" alt="">
									<img :src="require('@/assets/img/index_07.png')" alt=""><img
										:src="require('@/assets/img/index_09.png')" alt="">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="box-right-bottom">
					<el-tabs class="tab" type="border-card">
						<el-tab-pane label="Summary">
							<div style="summary_text">

								<div style="margin:20px 0px;"><span
										style="border:2px solid #01395a;padding:8px 0px;"></span><span
										style="background-color:hsl(228deg, 45.45%, 97.84%);padding:10px 0px 10px 4px;width:101%;display: inline-block;">
										This is the public page for a publication record in <span
											style="color:#004b6d">influence</span>,a free research insights plattform
										that brings together information about funding,scholarly outputs,policy,parents
										and grants.</span></div>
								<div style="margin-left:34px;">
									<p>This publication in Current Medicinal Chemistry has been cited 9 times, 88% of
										its citations have been received in the past two vears.</p>
									<p>Compared to other publications in the same feld, this publication is hiahw citeed
										approximately 4.03 times more citations than average.</p>
									<p>You are seeing a free-to-access but limited selection of the activity
										Contrimetric has collected about this research output. <span
											style="color:#409EFF;cursor: pointer;">Click here to find out more.</span>
									</p>
								</div>
							</div>
							<div style="display: flex;justify-content: space-between;margin:73px 0px;">
								<div class="b"><img :src="require('@/assets/img/w.png')" alt="">
									<div class="block_text"><span class="block_text1">3</span><br>
										<span>Citations</span>
									</div>
								</div>
								<div class="b"><img :src="require('@/assets/img/s.png')" alt="">
									<div class="block_text"><span class="block_text1">3</span><br>
										<span>Citations</span>
									</div>
								</div>
								<div class="b"><img :src="require('@/assets/img/c1.png')" alt="">
									<div class="block_text"><span class="block_text1">3</span><br>
										<span>Citations</span>
									</div>
								</div>
								<div class="b"><img :src="require('@/assets/img/n.png')" alt="">
									<div class="block_text"><span class="block_text1">3</span><br>
										<span>Citations</span>
									</div>
								</div>
								
							</div>
						</el-tab-pane>
						<el-tab-pane label="Citations">
							<div v-if="jianshe">
								<div style="margin:20px 0px;"><span
										style="border:2px solid #01395a;padding:8px 0px;"></span><span
										style="background-color:hsl(228deg, 45.45%, 97.84%);padding:10px 0px 10px 4px;width:101%;display: inline-block;">
										Contrimetric has found a total of <span style="font-weight: bold;">8</span>
										citations of this research output, All as shown below.</span></div>
								<div style="margin-left:34px;" v-for="(item,index) in articleItem" :key="index">
									<div class="article_block">
										<div>{{item.item}}</div>
										<div><span style='color:#5e5e5e'>Article in</span>{{item.item1}}</div>
									</div>

								</div>
								<div style="margin:60px 0px;display: flex;justify-content: right;">
									<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
										:current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="100"
										small layout="prev, pager, next" :total="400">
									</el-pagination>
								</div>
								<div id="main" style="width: 800px;height:500px;"></div>
							</div>
							<div v-else>
								<div style="text-align: center;">The website is under construction!</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="Citing Fundations"><div v-if="jianshe">
							<div style="margin:20px 0px;"><span
									style="border:2px solid #01395a;padding:8px 0px;"></span><span
									style="background-color:hsl(228deg, 45.45%, 97.84%);padding:10px 0px 10px 4px;width:101%;display: inline-block;">
									Contrimetric has found a total of <span style="font-weight: bold;">8</span>
									citations of this research output, All as shown below.</span></div>
							<div id="map" style="width: 800px;height:500px;"></div>
							</div>
							<div v-else>
								<div style="text-align: center;">The website is under construction!</div>
							</div>
						
						</el-tab-pane>
						<el-tab-pane label="Citing Geography">
							<div v-if="jianshe">
							<div style="margin:20px 0px;"><span
									style="border:2px solid #01395a;padding:8px 0px;"></span><span
									style="background-color:hsl(228deg, 45.45%, 97.84%);padding:10px 0px 10px 4px;width:101%;display: inline-block;">
									Contrimetric has found a total of <span style="font-weight: bold;">8</span>
									citations of this research output, All as shown below.</span></div>
							<div id="map" style="width: 800px;height:500px;"></div>
							</div>
							<div v-else>
								<div style="text-align: center;">The website is under construction!</div>
							</div>
						</el-tab-pane>

						<el-tab-pane label="Fame">
							<div v-if="jianshe">
								<div style="margin:20px 0px;"><span
										style="border:2px solid #01395a;padding:8px 0px;"></span><span
										style="background-color:hsl(228deg, 45.45%, 97.84%);padding:10px 0px 10px 4px;width:101%;display: inline-block;">
										Contrimetric has found a total of <span style="font-weight: bold;">8</span>
										citations of this research output, All as shown below.</span></div>
								<div style="margin-left:34px;" v-for="(item,index) in articleItem" :key="index">
									<div class="article_fame">
										<div class="article_fame_left"><img src="" alt=""></div>
										<div class="article_fame_right">
											<div>{{item.item}}</div>
											<div><b>Article in：</b><span style='color:#5e5e5e'>{{item.item1}}</span>
											</div>
										</div>
									</div>
									<div class="article1_fame">
										<div class="article1_fame_right">
											<img src="" alt=""><img src="" alt=""><img src="" alt=""><img src="" alt="">
										</div>
									</div>

								</div>
								<div style="margin:60px 0px;display: flex;justify-content: right;">
									<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
										:current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="100"
										small layout="prev, pager, next" :total="400">
									</el-pagination>
								</div>
							</div>
							<div v-else>
								<div style="text-align: center;">The website is under construction!</div>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
	</basic-container>
</template>

<script>
	import * as echarts from 'echarts';
	import {
		getMap
	} from "@/api/article/article";
	export default {
		name: 'index',
		data() {
			return {
				articleItem: [{
					item: 'sdsfdsfsdfsdfsdfsdfwercvzxczxcwqe',
					item1: '11sdsfdsfsdfsdfsdfsdfwercvzxczxcwqe',

				}, ], //论文数据
				currentPage4: 1,
				jianshe: false,
			}
		},
		mounted() {
			this.citedYear()
			this.geographyMap()
		},
		methods: {
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
			},
			citedYear() {
				var myChart = echarts.init(document.getElementById('main'));

				// 指定图表的配置项和数据
				var option = {
					title: {
						text: 'Citations by year',
					},
					tooltip: {},

					xAxis: {
						data: ['2020', '2021', '2022', '2023', '2024']
					},
					yAxis: {
						interval: 1
					},
					series: [{
						name: '销量',
						type: 'line',
						data: [0.2, 2.3, 3.6, 1, 1.4, 2],
						itemStyle: {
							color: '#1da6b8'
						}
					}]
				};

				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
			},
			//地图
			geographyMap() {

				var chartDom = document.getElementById('map');
				var myChart = echarts.init(chartDom);
				var option;

				myChart.showLoading();
				getMap().then(worldJson => {


					echarts.registerMap('world', worldJson);

					option = {
						geo: {
							map: 'world',
							tooltip: {
								show: true
							},
							roam: true,
							itemStyle: {
								areaColor: '#e7e8ea'
							}
						},
						tooltip: {},
						legend: {},
						series: [

						]
					};
					myChart.hideLoading();
					myChart.setOption(option);
				});

				option && myChart.setOption(option);
			}

		}
	}
</script>
<style lang="scss" scoped>
	.box {
		padding: 43px 58px 0px 24px;
		display: flex;
		background-color: hsl(228deg, 45.45%, 97.84%);
	}

	.box_left {
		width: 18%;
		margin-right: 5px;
	}

	.mark {
		text-align: center;
		position: relative;
	}

	.markText {
		font-weight: bold;
		color: black;
		position: absolute;
		top: 50%;
		/* 根据需要调整垂直位置，可以使用百分比 */
		left: 50%;
		/* 根据需要调整水平位置，可以使用百分比 */
		transform: translate(-50%, -50%);
		/* 将数字居中定位 */
	}


	// logo旋转效果
	.mark>img {
		width: 50%;
		transform-origin: center;
		/* 设置动画效果 */
		animation: rotateAnimation 60s infinite linear;
	}

	/* 定义旋转动画 */
	@keyframes rotateAnimation {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}


	.box_left_middle {
		margin-top: 15px;
	}

	.question {
		width: 18px;
		height: 18px;
	}

	.question0 {
		position: relative;
	}

	.question0:hover:after {
		position: absolute;
		bottom: 2rem;
		left: -14rem;
		width: 16rem;
		font-size: 0.675rem;
		content: attr(data-title);
		padding: 17px 10px 17px 10px;
		border: 1px solid #d4d4d6;
		border-radius: 5px;
		background-color: black;
		hyphens: auto;
		text-align: justify;
		text-indent: 0;
		color: #ffffff;
		z-index: 10000;
	}

	.box_left_middle {
		background-color: #fff;
		box-shadow: 3px 3px 12px 0px rgba(228, 228, 228, 0.5);
		margin-bottom: 60px;
	}

	.citation_block {
		display: flex;
	}

	.citation_block1 {
		flex: 1;
		border: 1px solid #f5f1f1;
		border-right: 0px;
	}

	.citation_block1>div:nth-child(2) {
		padding: 5px 0px;
		text-align: center;
	}

	.citation_block1>div:last-child {
		padding: 5px 0px;
		text-align: center;
	}

	.box_left_bottom {
		display: flex;
		justify-content: center;
	}

	.box_left_bottom_text {
		width: 266px;
		text-align: center;
		padding: 18px 10px;
		background-image: linear-gradient(0deg,
				#d7d7d7 0%,
				#ffffff 100%),
			linear-gradient(#01395a,
				#01395a);
		background-blend-mode: normal,
			normal;
		border-radius: 11px;
		border: solid 1px #01395a;
		font-family: PingFang-SC-Bold;
		letter-spacing: 0px;
		color: #01395a;
	}


	.box-right {
		flex: 1;
		padding: 0px 10px 0px 5px;
	}

	.box-right-top>p {
		margin: 0px;
		margin-bottom: 30px;
		font-family: PingFang-SC-Bold;
		font-size: 26px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 26px;
		letter-spacing: 0px;
		color: #000000;
	}

	.first {
		font-family: PingFang-SC-Bold;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 26px;
		letter-spacing: 0px;
		color: #535353;
	}

	.second_text {
		font-family: PingFang-SC-Regular;
		font-size: 16px;
		font-weight: normal;
		letter-spacing: 0px;
		color: #a8a8a8;
	}

	.text_left>p {
		margin: 5px;
	}

	.text-right>div:nth-child(1) {
		padding: 15px 10px;
		background-color: #01395a;
		border-radius: 11px;
		font-family: PingFang-SC-Bold;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 26px;
		letter-spacing: 0px;
		color: #f6f8fc;
		text-align: center;
	}

	.box-right-bottom {
		margin: 20px 0px;
	}

	.tab {
		padding: 25px 0px 0px 0px;
	}

	// 修改tab
	::v-deep .el-tabs--border-card>.el-tabs__header {
		background-color: #fff;
	}

	::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
		background-color: hsl(228deg, 45.45%, 97.84%);
		color: #01395a;
	}

	.summary_text>p {
		font-size: 14px;
	}

	.b {
		position: relative;
	}

	.block_text {
		position: absolute;
		color: #ffffff;
		top: 12px;
		left: 120px;
	}

	.block_text1 {
		font-weight: bold;
		font-size: 48px;
	}

	.article_block {
		padding: 15px 10px;
		border-bottom: 1px solid #e5e5e5;
	}

	.article_fame {
		padding: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
	}


	.article1_fame {
		border-bottom: 1px solid #e5e5e5;

	}

	.article_fame_left {
		width: 9%;
	}

	.article_fame_left>img {
		width: 80px;
		height: 80px;
		border: 1px solid black;
		border-radius: 50%;
	}

	.article_fame_right {
		flex: 1;
	}

	.article1_fame_right {
		margin-left: 140px;
	}

	.article1_fame_right>img {
		width: 45px;
		height: 45px;
		border: 1px solid black;
		margin-right: 30px;

	}
</style>