<template>
	<div class="bottomContainer">
		<div class="containerLeft"><span class="containerLeft_text">This page is provided by</span><img :src="require('@/assets/img/logo_bottom.png')" alt="">
			<!-- <span class="leftRight">Influences</span> -->
		</div>
		<div class="containerMiddle"></div>
		<div class="containerRight">Terms of use | Privacy policy</div>
	</div>
</template>

<script>
	export default {
		name: 'influenceBottom'
	}
</script>
<style>
	.bottomContainer {
		width: 100%;
		background-color: #01395a;
		padding: 13px 0px;
		display: flex;
		justify-content: space-between;
	}

	.containerLeft {
		display: flex;
		align-items: center;
		margin-left: 107px;
	}

	.leftRight {
		width: 169px;
		font-family: Raleway;
		font-size: 36px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 26px;
		letter-spacing: 0px;
		color: #fefefe;
	}
	
	.containerLeft_text {
		font-family: PingFang-SC-Regular;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 21px;
		letter-spacing: 0px;
		color: #aeaeae;
		margin-right:10px;
	}


	.containerLeft>img {
		height: 54px;
	}

	.containerRight {
		margin-right: 226px;
		font-family: PingFang-SC-Regular;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 21px;
		letter-spacing: 0px;
		color: #e1f8fa;
		display: flex;
		align-items: center;
	}
</style>