<template>
	<div class="basic-user-container">
		<influenceHeader>
		</influenceHeader>
		<slot></slot>
		<influenceBottom>
		</influenceBottom>
	</div>
</template>

<script>
	import influenceHeader from '@/components/common/header'
	import  influenceBottom from '@/components/common/bottom'
	export default {
		components: {
			influenceHeader,
			influenceBottom
		}
	}
</script>

<style lang="scss" scoped>
	.basic-user-container {
	  width: 100%;
	 
	  display: flex;
	  flex-direction: column;
	}
</style>