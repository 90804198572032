<template>
	<basic-container>
		<div class="box">
			<div class="box_top">
				<div class="box_top_middle">
					<div class="middle_head">DIRECTORY OF INFLUENCES JOURNALS</div>
					<div class="middle_head1">Find Influences Journals</div>
					<el-radio v-model="radio" label="0">Journals</el-radio>
					<el-radio v-model="radio" label="1">Articles</el-radio>
					<div class="search" v-if="radio == 0"> <el-input placeholder="Search Journals,ISSN or title word..."
							v-model="searchJournal" clearable @keyup.enter.native="searchJournalList" ></el-input>
						<el-select v-model="selectJournal" placeholder="In all fileds" @keyup.enter.native="searchJournalList">
							<el-option label="Title" value="0"></el-option>
							<el-option label="ISSN" value="1"></el-option>
							<el-option label="Publisher" value="2"></el-option>
						</el-select>
						<el-button icon="el-icon-search" @click="searchJournalList"   >SEARCH</el-button>
					</div>
					<div class="search" v-else> <el-input placeholder="Search Journals,author or abstract word..."
							v-model="searchArticle" clearable  @keyup.enter.native="searchArticleList"></el-input>
						<el-select v-model="selectArticle" placeholder="In all fileds"  @keyup.enter.native="searchArticleList">
							<el-option label="Title" value="0"></el-option>
							<el-option label="Author" value="1"></el-option>
							<el-option label="Abstract" value="2"></el-option>
						</el-select>
						<el-button icon="el-icon-search" @click="searchArticleList" >SEARCH</el-button>
					</div>
					<div class="middle_bottom">
						<div>
							<p>50</p>
							<p>LANGUAGES</p>
						</div>
						<div>
							<p>42510</p>
							<p>JOURNALS</p>
						</div>
						<div>
							<p>6022150</p>
							<p>ABTICLE RECORDS</p>
						</div>
						<div>
							<p>181</p>
							<p>COUNTRIES REPRESENTED</p>
						</div>
					</div>
				</div>

			</div>
			<div class="box_bottom">
				
			</div>
		</div>
	</basic-container>
</template>

<script>
	import {
	  searchJournals,
	  searchArticle
	} from "@/api/index/index";
	export default {
		
		name: 'index',
		data() {
			return {
				radio: '0', //切换搜索输入框
				searchJournal: '', //搜索期刊的输入框
				selectJournal: '', //期刊选项
				searchArticle: '', //搜索文章的输入框
				selectArticle: '', //文章选项
			}
		},
		created() {

		},
		methods: {
			// 查询期刊列表
			searchJournalList(){
				
				let paramap = {
					type:this.searchJournal,
					select:this.selectJournal
				}
				// searchJournals(paramap).then(res=>{
					
				// })
			},
			// 查询论文列表
			searchArticleList(){
				let paramap = {
					type:this.searchArticle,
					select:this.selectArticle
				}
				// searchArticle(paramap).then(res=>{
					
				// })
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box {
		width: 100%;
	}

	.box_top {
		height: 800px;
		background-image: url(@/assets/img/index_bg.png);
	}

	.box_top_middle {
		width: 60%;
		padding: 160px 0px 40px 0px;
		margin: 0 auto;
	}

	.middle_head {
		font-family: MicrosoftTaiLe-Bold;
		font-size: 18px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 26px;
		letter-spacing: 0px;
		color: #01395a;
	}

	.middle_head1 {
		font-family: FZLTTHK--GBK1-0;
		font-size: 36px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 26px;
		letter-spacing: 0px;
		color: #000000;
		padding: 20px 0px 30px 0px;
	}

	//修改单选框
	::v-deep .el-radio__inner {
		border: 1px solid #434343;
		background-color: unset;
	}

	::v-deep .el-radio__input.is-checked .el-radio__inner {
		background-color: #01395a;
	}

	::v-deep .el-radio__inner::after {
		width: unset;
		height: unset;
	}

	::v-deep .el-radio__input.is-checked+.el-radio__label {
		color: black;
	}

	::v-deep .el-radio__input.is-checked .el-radio__inner {
		border: 1px solid #434343;
	}

	::v-deep .el-radio__inner:hover {
		border-color: unset;
	}

	//修改输入框
	.search {
		padding: 15px 0px 280px 0px;
		display: flex;
		width: 75%;
	}

	::v-deep .search .el-input__inner {
		border: 1px solid #434343;
		border-right: unset;
		background-color: unset !important;
	}

	::v-deep .search .el-input input::-webkit-input-placeholder {
		color: #434343;
	}

	::v-deep .search .el-input input::-moz-input-placeholder {
		color: #434343;
	}

	::v-deep .search .el-input input::-ms-input-placeholder {
		color: #434343;
	}

	::v-deep .search .el-icon-arrow-up:before {
		color: #000000;
	}

	::v-deep .search .el-icon-circle-close:before {
		color: #000000;
	}

	::v-deep .el-select .el-input .el-select__caret {
		color: unset;
	}

	::v-deep .el-select .el-input__inner:focus {
		border-color: black;
	}

	::v-deep .el-select .el-input.is-focus .el-input__inner {
		border-color: black;
	}

	::v-deep .el-select:hover .el-input__inner {
		border-color: black;
	}


	::v-deep .search .el-button {
		border: 1px solid #434343;
		background-color: unset !important;
		color: #434343;
	}

	.middle_bottom {
		display: flex;
	}

	.middle_bottom>div {
		border-left: 1px solid black;
		padding: 0px 40px;
	}

	.middle_bottom>div>p:nth-child(1) {
		margin: 10px 0px;
		font-family: Amicale-Regular;
		font-size: 48px;
		font-weight: 500;
		font-stretch: normal;
		line-height: 26px;
		letter-spacing: 0px;
		color: #000000;
	}

	.middle_bottom>div>p:nth-child(2) {
		margin: 0px;
			font-family: ArialMT;
			font-size: 16px;
			line-height: 26px;
			letter-spacing: 0px;
			color: #717171;
		}

		.box_bottom {
			height: 300px;
			background-color: #f5f9fd;
		}
</style>