import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "@/store/index.js"
import axiosInstance from './utils/request.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import basicContainer from '@/components/common/basicContainer'


Vue.config.productionTip = false
Vue.prototype.$http = axiosInstance
Vue.use(ElementUI).use(router)
Vue.component('basicContainer', basicContainer)

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
