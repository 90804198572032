import Vue from 'vue'
import Vuex from "vuex"

Vue.use(Vuex)
const store = new Vuex.Store({
	state:{
		str:'ha ha'
	},
	getters:{
		
	},
	mutations:{
		
	},
	actions:{
		
	}
	
})

export default store //导出文件末位要换行